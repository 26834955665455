import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { gql, useApolloClient } from '@apollo/client';

import { useAuth } from 'context/AuthProvider';
import { delay } from 'context/AnalyticsProvider';
import useEmailAuth from 'context/useEmailAuth';
import subscriptionTypes from './subscription-types';
import Snackbar from 'components/Snackbar';
import Heading from 'libs/heading';
import Checkbox from 'components/forms/NewCheckbox';
import AuthLogOut from 'components/authentication/AuthLogOut';
import Button from 'components/forms/Button';
import Error from 'components/error/Error';

const FormWrapper = styled.form`
	margin-bottom: 40px;
	background: white;
	box-shadow: ${p => p.theme.utils.boxShadow};
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: 40px;
	${p =>
		p.theme.media.XSmallOnly(css`
			padding: 20px;
			margin-bottom: 30px;
		`)}
	h3 {
		margin-bottom: 10px;
	}
`;

const Desc = styled.p`
	margin-bottom: 30px;
`;

const Groups = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		margin-bottom: 30px;
		label {
			margin: 0;
		}
	}
`;

const Footer = styled.div`
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: 0;
			flex-direction: column;
			align-items: flex-start;
		`)}

	.auth-logout {
		margin: 20px 0 0 auto;
		${p =>
			p.theme.media.medium(css`
				margin: 0 0 0 35px;
			`)}
	}
`;

/**
 * Subscription types form (Intercom)
 * @param {Object} contact - Contact data
 * @param {String} contactError - Contact error message
 * @param {Function} refetchContact - Refetch contact data
 * @param {Object} props - Component props
 * @returns {JSX.Element}
 */
export default function SubscriptionTypesForm({
	contact,
	contactError,
	refetchContact,
	...props
}) {
	const [acceptedTypes, setAcceptedTypes] = useState(contact?.subscriptions);
	const [error, setError] = useState(false);
	const [saving, setSaving] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const client = useApolloClient();
	const { isAuthenticated: isVipps } = useAuth();
	const { isAuthenticated: isEmailAuth } = useEmailAuth();

	/**
	 * Update accepted types when contact data changes
	 * @param {Object} contact - Contact data
	 * @returns {void}
	 */
	async function saveConsentChanges() {
		setSaving(true);
		setError(false);

		sessionStorage.setItem(
			'nte-stored-subscriptions',
			JSON.stringify({
				subscriptions: acceptedTypes,
				timestamp: Date.now(),
			})
		);

		/**
		 * Filter out changes in subscription status
		 * @type {Array}
		 * @returns {Array}
		 */
		const changes = subscriptionTypes?.filter(type => {
			const previouslyOptedIn =
				contact?.subscriptions?.includes(type?.id) &&
				type?.consent_type === 'opt_in';
			const nowOptedIn = acceptedTypes?.includes(type?.id);
			return previouslyOptedIn !== nowOptedIn;
		});

		/**
		 * Loop through changes and update subscription status
		 */
		if (changes?.length > 0) {
			for (let i = 0; i < changes?.length; i++) {
				// Delay
				await delay(500);

				const subscription = changes[i];

				const { loading: updateLoading, error: updateError } =
					await client.mutate({
						mutation: gql`
							mutation updateSubscriptionStatus(
								$subscription: SubscriptionType!
								$method: String!
								$event: String
								$action: String
							) {
								updateSubscriptionStatus(
									subscription: $subscription
									method: $method
									event: $event
									action: $action
								)
							}
						`,
						variables: {
							subscription: {
								id: subscription?.id,
								title: subscription?.title,
								consent_type: subscription?.consent_type,
							},
							method: acceptedTypes?.includes(subscription?.id)
								? 'add'
								: 'remove',
							event: props?.event,
							action: props?.action,
						},
					});

				if (!updateLoading && updateError) {
					console.error(updateError);
					setError(true);
					setSaving(false);
				}
			}

			await delay(500);
		}

		setSaving(false);
		setSubmitted(true);

		// Refetch contact data after 2 seconds
		await delay(2000).then(async () => {
			console.log('refetching contact');
			await refetchContact();
		});
	}

	// If error or no contact, show error message
	if ((error || contactError) && !saving) {
		return (
			<Error
				centered={true}
				resolver="getIntercomContact"
				errorMessage={error || contactError || 'No contact data'}
			/>
		);
	}

	return (
		<>
			{/* Snackbar with success message */}
			{submitted && !saving && !error && (
				<Snackbar
					data-cy="preferences-portal-subscription-update"
					messages={[
						{
							_id: 'done-subscription-update',
							text: 'Dine samtykker er oppdatert og lagret 👍',
						},
					]}
					onClose={() => {
						setSubmitted(false);
					}}
				/>
			)}

			{/* Form with checkbox for each subscription type */}
			<FormWrapper
				role="form"
				onSubmit={e => {
					e.preventDefault();
					saveConsentChanges();
				}}>
				<section>
					<Heading level="h3">Mine samtykker</Heading>

					<Desc>
						Vi ønsker at informasjonen du får fra oss er mest mulig
						nyttig og relevant for deg. For å kunne tilpasse
						informasjonen til dine behov trenger vi ditt samtykke.
					</Desc>

					{subscriptionTypes?.length > 0 && (
						<Groups data-cy="subscription-groups">
							{subscriptionTypes
								?.filter(type => !!type.samtykke_portal)
								?.map(type => {
									return (
										<li
											key={type?.id}
											data-cy="subscription-group">
											<Checkbox
												id={type?.title}
												name={type?.title}
												label={type?.label}
												helpText={type?.desc}
												checked={acceptedTypes?.includes(
													type?.id
												)}
												onChange={e => {
													const isChecked =
														e.target.checked;
													if (isChecked) {
														setAcceptedTypes(
															prev => [
																...prev,
																type?.id,
															]
														);
													} else {
														setAcceptedTypes(prev =>
															prev.filter(
																id =>
																	id !==
																	type?.id
															)
														);
													}
												}}
											/>
										</li>
									);
								})}
						</Groups>
					)}
				</section>

				<Footer>
					<Button
						type="submit"
						data-cy="preferences-portal-save-subscription-updates"
						disabled={saving}
						loading={(saving && 'true') || 'false'}>
						{(saving && 'Lagrer samtykker') || 'Lagre samtykker'}
					</Button>

					{(isVipps || isEmailAuth) && <AuthLogOut />}
				</Footer>
			</FormWrapper>

			{/* Error message */}
			{submitted && error && !saving && (
				<Error
					resolver="updateSubscriptionStatus"
					errorMessage={error}
				/>
			)}
		</>
	);
}
