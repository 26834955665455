const types = process.env.GATSBY_INTERCOM_EMAIL_SUBSCRIPTION_TYPES;

const typesObj = types
	? JSON.parse(process.env.GATSBY_INTERCOM_EMAIL_SUBSCRIPTION_TYPES)
	: {};

const subscriptionTypes = [
	{
		title: 'Markedsføring',
		id: typesObj['Markedsføring'],
		label: 'Vil du motta tilbud, informasjon og tips fra NTE elektronisk?',
		desc: 'Ja, jeg ønsker å motta elektronisk markedsføring med gode råd og tilbud tilpasset meg.',
		consent_type: 'opt_in',
		samtykke_portal: true,
	},
	{
		title: 'Beta-tester',
		id: typesObj['Betatester'],
		label: 'Vil du være med å teste nye produkter og tjenester fra NTE?',
		desc: 'Ja, kontakt meg gjerne for å teste nye produkter og tjenester fra NTE. Dette kan for eksempel være intervju, brukertester eller andre aktiviteter.',
		consent_type: 'opt_in',
		samtykke_portal: true,
	},
	{
		title: 'Gir Mer',
		id: typesObj['GirMer'],
		label: 'Vil du motta tilbud fra fordelsprogrammet Gir Mer elektronisk?',
		desc: 'Ja, jeg ønsker å motta elektronisk markedsføring med gode tilbud fra fordelsprogrammet Gir Mer.',
		consent_type: 'opt_in',
		samtykke_portal: false,
	},
	{
		title: 'Strømvarselet',
		id: typesObj['Strømvarselet'],
		label: 'Ønsker du å motta Strømvarselet på e-post?',
		desc: 'Ja, send meg strømvarselet på e-post to ganger i måneden.',
		consent_type: 'opt_in',
		samtykke_portal: false,
	},
	{
		title: 'Strømpris Varsel E-post',
		id: typesObj['Strømpris Varsel E-post'],
		label: 'Vil du motta strømprisvarsel på e-post?',
		desc: 'Ja, jeg vil motta varsel om strømprisendringer på e-post.',
		consent_type: 'opt_in',
		samtykke_portal: false,
	},
];

export const smsSubscriptionTypes = [
	{
		title: 'Strømpris Varsel SMS',
		id: typesObj['Strømpris Varsel SMS'],
		label: 'Vil du motta strømprisvarsel på SMS?',
		desc: 'Ja, jeg vil motta varsel om strømprisendringer på SMS.',
		consent_type: 'opt_in',
		samtykke_portal: false,
	},
];

export const pushSubscription = {
	title: 'Mine push-preferanser',
	intro: 'Her kan du aktivere push-notifikasjoner i nettleseren.',
	id: 'push-info-tjenester-produkter',
	label: 'Vil du motta tilbud, informasjon og tips fra NTE elektronisk?',
	desc: 'For å kunne gi råd og tilbud tilpasset deg i digitale kanaler, ber vi om ditt samtykke til elektronisk markedsføring. ',
};

export default subscriptionTypes;
