import React, { useState } from 'react';
import styled from 'styled-components';
import { gql, useApolloClient } from '@apollo/client';

import subscriptionTypes from './subscription-types';
import Checkbox from 'components/forms/NewCheckbox';
import { AnchorLink } from 'components/Link';

const CheckboxTitle = styled.div`
	font-weight: 500;
	margin: 0 0 10px;
`;
/**
 * Represents a custom hook for saving subscription consent for a contact in Intercom
 * @returns {object} - The saveConsent function and error state
 */
export default function useSaveConsent() {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const client = useApolloClient();

	/**
	 * Saves the marketing consent for a contact in Intercom
	 * @param {object} props - The props
	 * @param {string} [props.event=''] - The event name
	 * @param {string} [props.email=''] - The email address
	 * @param {string} [props.phone=''] - The phone number
	 * @param {string} [props.action=''] - The action that happened along with the consent, if any
	 * @param {string} [props.method='add'] - The method for saving the consent (add, remove)
	 * @param {string} [props.subscription='Markedsføring'] - The subscription type title to save
	 * @returns {boolean} - The success state
	 */
	async function saveConsent({
		event = '',
		email = '',
		phone = '',
		action = '',
		method = 'add',
		subscription = 'Markedsføring',
	}) {
		try {
			if (!subscriptionTypes?.length > 0 || !email) {
				throw new Error('Missing required data');
			}

			const { id, title, consent_type } = subscriptionTypes?.find(
				type => type?.title === subscription
			);

			if (!id) {
				throw new Error(`Subscription type ${subscription} not found`);
			}

			setLoading(true);

			const { loading: updateLoading, error: updateError } =
				await client.mutate({
					mutation: gql`
						mutation updateSubscriptionStatus(
							$subscription: SubscriptionType!
							$method: String!
							$event: String
							$action: String
							$email: String
							$phone: String
						) {
							updateSubscriptionStatus(
								subscription: $subscription
								method: $method
								event: $event
								action: $action
								email: $email
								phone: $phone
							)
						}
					`,
					variables: {
						subscription: {
							id,
							title,
							consent_type,
						},
						method,
						event,
						action,
						email,
						phone,
					},
				});

			setLoading(false);

			if (!updateLoading && updateError) {
				setError(updateError?.message);
				throw new Error(updateError?.message);
			}

			return true;
		} catch (e) {
			console.error(`saveConsent failed: ${e?.message}`);
			setError(e);
			return false;
		}
	}

	return {
		saveConsent,
		loading,
		error,
	};
}

/**
 * Represents a checkbox for subscription consent
 * @param {object} props - The props
 * @param {string} [props.id='subscription-consent'] - The id of the checkbox
 * @param {string} [props.name='subscription-consent'] - The name of the checkbox
 * @param {string} [props.title='Vil du motta tilbud, informasjon og tips fra NTE elektronisk?'] - The title of the checkbox
 * @param {boolean} [props.defaultChecked=false] - The default checked state
 * @param {function} [props.onChange=()=>{}] - The onChange event handler
 * @param {boolean} [props.required=false] - The required flag
 * @param {object} [props.style] - The style object
 * @returns {JSX.Element} - The marketing consent checkbox
 */
export function ConsentCheckbox({
	id = 'subscription-consent',
	name = 'subscription-consent',
	title = 'Vil du motta tilbud, informasjon og tips fra NTE elektronisk?',
	defaultChecked = false,
	onChange = () => {},
	required = false,
	style,
}) {
	/**
	 * Handles the change event for the checkbox
	 * @param {Event} e - The event object
	 * @returns {void}
	 */
	function handleChange(e) {
		if (!e?.target) return;
		const isChecked = e?.target?.checked;
		if (isChecked) {
			e.target.setAttribute('checked', true);
		} else {
			e.target.removeAttribute('checked');
		}
	}

	return (
		<div style={style} className="subscription-consent">
			{title && (
				<CheckboxTitle className="subscription-consent__title">
					{title}
				</CheckboxTitle>
			)}

			<Checkbox
				tabIndex={0}
				id={id}
				name={name}
				className="subscription-consent__checkbox"
				data-cy="subscription-consent-checkbox"
				defaultChecked={defaultChecked}
				onChange={e => {
					handleChange(e);
					if (onChange) onChange(e);
				}}
				onKeyDown={e => {
					if (!['Enter', 'Space'].includes(e?.key)) return;
					handleChange(e);
					if (onChange) onChange(e);
				}}
				required={required}>
				<span
					data-cy="subscription-consent-label"
					style={{ fontWeight: 400 }}>
					Ja, jeg vil motta tilbud, informasjon og tips fra NTE
					elektronisk
				</span>
			</Checkbox>
		</div>
	);
}

export async function findContact({ client, email, phone }) {
	try {
		const {
			data: { getIntercomContact: contactData },
			loading: contactLoading,
			error: contactError,
		} = await client.query({
			query: gql`
				query getIntercomContact($email: String, $phone: String) {
					getIntercomContact(email: $email, phone: $phone) {
						intercom_id
						external_id
						first_name
						last_name
						name
						email
						phone
						subscriptions
						unsubscribed_from_emails
					}
				}
			`,
			variables: { email, phone },
		});

		if (contactError && !contactLoading) {
			throw new Error(
				`findContact request failed: ${contactError?.message}`
			);
		}

		return contactData;
	} catch (error) {
		console.log(error);
		throw new Error('findContact failed ' + error?.message);
	}
}

/**
 * Represents a checkbox for privacy policy consent
 * @param {object} props - The props
 * @param {string} [props.title='Personvernerklæring'] - The title of the checkbox
 * @param {string} [props.company='NTE'] - The company name
 * @param {string} [props.id='privacy-policy-consent'] - The id of the checkbox
 * @param {string} [props.name='privacy-policy-consent'] - The name of the checkbox
 * @param {boolean} [props.defaultChecked=false] - The default checked state
 * @param {function} [props.onChange=()=>{}] - The onChange event handler
 * @param {boolean} [props.required=true] - The required flag
 * @param {boolean} [props.hidetitle='false'] - The hide title flag
 * @param {string} [props.children] - The children of the checkbox
 * @param {object} [props.style] - The style object
 */
export function PrivacyPolicyCheckbox({
	title = 'Personvernerklæring',
	company = 'NTE AS',
	id = 'privacy-policy-consent',
	name = 'privacy-policy-consent',
	defaultChecked = false,
	onChange = () => {},
	required = true,
	hidetitle = 'false',
	children,
	style,
}) {
	/**
	 * Handles the change event for the checkbox
	 * @param {Event} e - The event object
	 * @returns {void}
	 */
	function handleChange(e) {
		if (!e?.target) return;
		const isChecked = e?.target?.checked;
		if (isChecked) {
			e.target.setAttribute('checked', true);
		} else {
			e.target.removeAttribute('checked');
		}
	}

	return (
		<div style={style} className="privacy-policy-consent">
			{hidetitle !== 'true' && (
				<CheckboxTitle className="privacy-policy-consent__title">
					{title}
				</CheckboxTitle>
			)}

			<Checkbox
				tabIndex={0}
				id={id}
				name={name}
				className="privacy-policy-consent__checkbox"
				data-cy="privacy-policy-consent-checkbox"
				defaultChecked={defaultChecked}
				onChange={e => {
					handleChange(e);
					if (onChange) onChange(e);
				}}
				onKeyDown={e => {
					if (!['Enter', 'Space'].includes(e?.key)) return;
					handleChange(e);
					if (onChange) onChange(e);
				}}
				required={required}>
				<span
					data-cy="privacy-policy-consent-label-span"
					style={{ fontWeight: 400 }}>
					{children || (
						<>
							<>
								Ja, jeg aksepterer at {company || 'NTE AS'} tar
								vare på de opplysningene jeg har lagt inn og{' '}
							</>
							<AnchorLink
								href="/om-nte/personvernerklaering"
								target="_blank"
								title="Les personvernerklæringen til NTE.no">
								NTE sin personvernerklæring
							</AnchorLink>
						</>
					)}
					{required && <Req> *</Req>}
				</span>
			</Checkbox>
		</div>
	);
}

const Req = styled.span`
	color: ${p => p.theme.colors.coral800};
`;
