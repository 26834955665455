import React from 'react';
import styled from 'styled-components';

import AuthButtons from 'components/authentication/AuthButtons';
import AuthInformation from 'components/authentication/AuthInformation';

const LoginWrapper = styled.div`
	max-width: ${p => p.theme.widths.small};
	margin: 0 auto;
	text-align: center;
	h2 {
		font-weight: 500;
		margin-bottom: 10px;
	}
`;

export default function Login({ isLoggedIn }) {
	if (isLoggedIn) return;

	return (
		<>
			<LoginWrapper>
				<AuthButtons
					title="Identifiser deg med vipps"
					redirect="/samtykke-portal"
					headinglevel="h2"
					headinglevelclass="h3"
					bankID={false}
					centered="true"
				/>

				<AuthInformation
					id="consent-portal-popup"
					bankID={false}
					type="identification"
				/>
			</LoginWrapper>
		</>
	);
}
