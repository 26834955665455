import React from 'react';
import styled, { css } from 'styled-components';

import { formatPhoneNumber } from 'libs/content';

const Info = styled.div`
	text-align: center;
	margin: 45px 0 0;
	${p =>
		p.theme.media.smallOnly(css`
			width: max-content;
			margin: 30px auto 0;
		`)}
	> p {
		margin-bottom: 10px !important;
		font-size: 17px !important;
		line-height: 29px !important;
	}
`;

const Row = styled.div`
	text-align: center;
	${p =>
		p.theme.media.medium(css`
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		`)}
`;

const Column = styled.div`
	${p =>
		p.theme.media.medium(css`
			margin-left: 0px;
			padding: 0 20px;
			&:not(:last-of-type) {
				border-right: 1px solid ${p => p.theme.colors.grey300};
			}
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			padding: 5px 0;
			width: 100%;
			&:not(:last-of-type) {
				border-bottom: 1px solid ${p => p.theme.colors.grey300};
			}
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			padding: 2.5px 0;
		`)}
`;

const Key = styled.span`
	font-weight: 500;
`;

const Value = styled.span`
	i {
		font-style: normal;
		font-weight: 500;
	}
	button {
		font-weight: 500;
		font-size: 17px;
	}
	svg {
		width: 20px;
		height: 20px;
		margin-left: 15px;
		margin-right: 10px;
		vertical-align: sub;
		${p =>
			p.theme.media.XSmallOnly(css`
				margin-left: 0px;
			`)}
	}
`;

export default function ContactInfo({ user }) {
	if (!user) return;

	return (
		<Info>
			<p>Vi har registrert følgende om deg:</p>
			<Row>
				{user?.name && (
					<Column>
						<Key>Navn:</Key> <Value>{user?.name}</Value>
					</Column>
				)}
				{user?.email && (
					<Column>
						<Key>E-post:</Key>{' '}
						<Value>
							<span>{user?.email}</span>
						</Value>
					</Column>
				)}
				{user?.phone && (
					<Column>
						<Key>Telefon:</Key>{' '}
						<Value>{formatPhoneNumber(user?.phone)}</Value>
					</Column>
				)}
			</Row>
		</Info>
	);
}
